export const dashboardRouteArgs: RequestInit = {
  method: 'GET',
  headers: {
    Accept: 'application/com.toasttab.payrollDashboard.v1+json',
    'Cache-Control': 'no-store'
  },
  credentials: 'same-origin'
}

export interface SsnCaptureStatus {
  status: boolean
}

export const getSsnCaptureRequirement = () =>
  fetch(`/human-resources/employees/ssn/status`, {
    method: 'GET',
    headers: {
      Accept: 'application/com.toasttab.payrollDashboard.v1+json',
      'Cache-Control': 'no-store'
    },
    credentials: 'same-origin'
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response.statusText)
      }
    })
    .catch((e) => {
      console.error('Error fetching SSN capture status', e)
    })

export const saveSsn = (ssn: string) =>
  fetch(`/human-resources/employees/ssn`, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/com.toasttab.payroll.v1+json',
      'Content-Type': 'application/com.toasttab.payroll.v1+json',
      'Cache-Control': 'no-store'
    },
    body: JSON.stringify({ ssn })
  }).then((res) => {
    if (res.status === 409) {
      console.error('Error saving SSN capture from Dashboard: Duplicate SSN')
      throw new Error('ssnExistsError')
    }
    if (!res.ok) {
      console.error('Error saving SSN capture from Dashboard: Generic Error')
      throw new Error('ssnCaptureGenericError')
    }
  })

export interface Alert {
  level: string
  name: string
}

export const fetchAlerts = () =>
  fetch(`/dashboard/alerts`, {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-store',
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin'
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response.statusText)
      }
    })
    .catch((e) => {
      console.error('Error fetching alerts', e)
    })
