import React from 'react'
import { AlertVariant } from '@toasttab/buffet-pui-alerts'
import { ReactElement } from 'react'

export class AlertContents {
  type: string = ''
  title: ReactElement = (<></>)
  message: string = ''
  level: AlertVariant | undefined = undefined
  callToAction?: string = undefined
  link?: string = undefined
}

export class MissingSSNsAlert extends AlertContents {
  constructor(customerCode: string) {
    super()
    this.title = (
      <div className='font-bold'>
        Update missing{' '}
        <a
          href='https://central.toasttab.com/s/article/Toast-Payroll-Employee-Social-Security-Numbers'
          target='blank'
          rel='noopener'
          className='inline-link'
        >
          employee SSNs
        </a>{' '}
        to ensure complete and timely payroll tax filings
      </div>
    )
    this.type = 'MissingSSNs'
    this.message =
      'Social Security numbers are needed to ensure complete payroll tax filings and generate accurate employee W-2s.'
    this.level = 'error'
    this.callToAction = 'Update SSNs'
    this.link = `/mvc/${customerCode}/Company/Setup/MissingData`
  }
}

export class MissingTaxAccountInformationAlert extends AlertContents {
  constructor(customerCode: string) {
    super()
    this.title = (
      <div className='font-bold'>Add missing tax account information</div>
    )
    this.type = 'MissingTaxAccountInformation'
    this.message =
      'State tax account numbers are needed to ensure timely filings/payments. You may be charged a $100 fee if the missing state tax account number is not provided within 30 days of your first check date.'
    this.level = 'warning'
    this.callToAction = 'Update tax info'
    this.link = `/mvc/${customerCode}/Company/Setup/MissingData`
  }
}

export function parseAlerts(
  alerts: any,
  customerCode: string
): AlertContents[] {
  var parsedAlerts: AlertContents[] = []

  if (alerts != null && alerts.length > 0) {
    alerts.forEach((alert: any) => {
      switch (alert.name) {
        case 'MissingSSNs':
          parsedAlerts.push(new MissingSSNsAlert(customerCode))
          break
        case 'MissingTaxAccountInformation':
          parsedAlerts.push(new MissingTaxAccountInformationAlert(customerCode))
          break
        default:
          break
      }
    })
  }
  return parsedAlerts
}
