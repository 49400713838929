import React from 'react'
import { AlertContents } from './alerts'
import { Alert } from '@toasttab/buffet-pui-alerts'

type AlertListProps = {
  alerts?: AlertContents[]
}

export const AlertList = ({ alerts }: AlertListProps) => {
  return <div>{alerts?.map((alert) => getAlertRow(alert))}</div>
}

const getAlertRow = (alert: AlertContents) => {
  const { title, message, level, callToAction, link } = alert
  return (
    <div className='px-4 py-3 w-full'>
      <Alert variant={level} outlined className='w-full'>
        <div>{title}</div>
        <div>{message}</div>
        <a href={link} className='inline-link-inherit'>
          {callToAction}
        </a>
      </Alert>
    </div>
  )
}
