import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'

import ApplicationRoot from './app/ApplicationRoot'
import { domElementGetter } from '@toasttab/ec-layout'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

JavascriptTimeAgo.addDefaultLocale(en)

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-ec-dash',
  rootComponent: ApplicationRoot,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer'],

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-dashboard-spa'
