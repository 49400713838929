import * as React from 'react'
import { NotificationIcon as BuffetNotificationIcon } from '@toasttab/buffet-pui-icons'

type Props = {
  alertCount: number
  onClick?: () => void
}

// Above this we'll show 99+
const MAX_ALERT_COUNT = 99

export const NotificationIcon = ({ alertCount, onClick }: Props) => {
  const displayAlertCount =
    '' + (alertCount > MAX_ALERT_COUNT ? `${MAX_ALERT_COUNT}+` : alertCount)
  const className = 'relative flex ' + (onClick ? 'cursor-pointer' : '')

  //'\u00A0' == &nbsp;
  const spacer =
    displayAlertCount.length > 1
      ? '\u00A0'.repeat(displayAlertCount.length)
      : ''

  return (
    <div
      className={className}
      data-testid='notification-icon'
      onClick={onClick}
    >
      <BuffetNotificationIcon size='md' />
      {alertCount > 0 && (
        <div
          className='absolute font-semibold text-white rounded-full bg-brand-50 type-caption'
          style={{
            top: '-1px',
            left: '17px',
            padding: '0 4px'
          }}
          data-testid='alert-icon-count'
        >
          {displayAlertCount}
        </div>
      )}
      {spacer}
    </div>
  )
}
