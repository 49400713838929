import * as React from 'react'
import { useCallback } from 'react'
import { Task as TaskType, TaskState } from '../constants'
import {
  CheckCircleSelectedFillIcon,
  CheckCircleUnselectedIcon,
  ChevronRightIcon,
  LockLockedIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

//
// interface Task {
//   testId: string
//   state: TaskState
//   url: string | undefined
//   icon: JSX.Element
//   label: string
//   sublabel: string
//   buttonTxt: string | undefined
//   onBtnClick: (() => void) | undefined
// }

function getTask(task: TaskType) {
  return (
    <div
      className='p-4 rounded-lg shadow bg-primary-0'
      data-testid={task.testId}
      data-test-state={task.state}
    >
      <div
        className='flex'
        onClick={() => {
          if (task.url && task.state === TaskState.Pending) {
            window.location.href = task.url
          }
        }}
      >
        <div className='self-center pr-3'>{task.icon}</div>
        <div className='flex-1'>
          <div
            className={`type-large mb-1 ${
              task.state === TaskState.Completed
                ? 'text-secondary'
                : 'font-semibold'
            }`}
          >
            {task.label}
          </div>
          <div className='text-secondary type-subhead'>{task.sublabel}</div>
        </div>
        <div className='self-center'>
          <ChevronRightIcon />
        </div>
      </div>
      {task.buttonTxt ? (
        <div className='w-full mt-3' data-test-task-btn={task.testId}>
          <Button
            className='w-full whitespace-nowrap'
            onClick={task.onBtnClick}
          >
            {task.buttonTxt}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export const Task = ({
  label,
  sublabel,
  state,
  url,
  buttonTxt,
  onBtnClick,
  tooltipText,
  testId
}: TaskType) => {
  const icon = getIcon(state)

  const getTooltipContent = useCallback(() => {
    return <div>{tooltipText}</div>
  }, [tooltipText])

  if (tooltipText) {
    return (
      <Tooltip content={getTooltipContent}>
        {getTask({
          testId: testId,
          state: state,
          url: url,
          icon: icon,
          label: label,
          sublabel: sublabel,
          buttonTxt: buttonTxt,
          onBtnClick: onBtnClick
        })}
      </Tooltip>
    )
  }

  return getTask({
    testId: testId,
    state: state,
    url: url,
    icon: icon,
    label: label,
    sublabel: sublabel,
    buttonTxt: buttonTxt,
    onBtnClick: onBtnClick
  })
}

const getIcon = (state: TaskState) => {
  switch (state) {
    case TaskState.Completed:
      return (
        <span className='text-primary-75'>
          <CheckCircleSelectedFillIcon size='md' />
        </span>
      )
    case TaskState.Locked:
      return <LockLockedIcon />
    default:
      return (
        <span className='text-gray-75'>
          <CheckCircleUnselectedIcon size='md' />
        </span>
      )
  }
}
