import * as React from 'react'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Task } from './Task'
import { DataType, Task as TaskType, TaskState } from '../constants'
import { getCompletedTasksList } from '../data_helpers'
import { track } from '@local/tracking'
import { Button } from '@toasttab/buffet-pui-buttons'

type Props = {
  tasks?: Array<TaskType>
  isEmployerChecklist: boolean
  data?: DataType
  setTaskState: (field: string, state: boolean) => void
}

const Checklist = ({
  tasks,
  isEmployerChecklist,
  data,
  setTaskState
}: Props) => {
  useEffect(() => {
    track('OnboardingChecklistShown', {
      onboardingChecklistType: isEmployerChecklist
        ? 'Employer Checklist'
        : 'Employee Checklist',
      completedChecklistTasks: getCompletedTasksList(tasks).join(',')
    })
  }, [tasks, isEmployerChecklist])

  if (!tasks) {
    return (
      <div className='leading-contained'>
        <div className='w-2/3'>
          <Skeleton height={20} />
        </div>
        <div className='w-3/4'>
          <Skeleton height={20} />
        </div>
        <Skeleton height={20} />
        <Skeleton count={5} height={65} />
      </div>
    )
  }

  const isEmployeeChecklistComplete = () =>
    !isEmployerChecklist &&
    tasks.filter((task) => task.state === TaskState.Completed).length ===
      tasks.length

  window.addEventListener('beforeunload', () => {
    if (
      isEmployeeChecklistComplete() &&
      !data?.employeeChecklist?.confirmedCompletion
    ) {
      setTaskState('confirmedCompletion', true)
    }
  })

  return (
    <div data-testid='onboarding-checklist'>
      <div className='mb-3 font-semibold type-headline-3'>
        {isEmployeeChecklistComplete() ? (
          <>
            <div>
              <div className='mb-3 font-semibold type-headline-3'>
                Great job!
              </div>
              <div className='mb-5 text-secondary type-default'>
                You're now ready to get paid.
              </div>
            </div>
            <div className='flex flex-col space-y-3'>
              <div className='flex flex-row items-center space-x-3'>
                <Button
                  className='hideChecklist'
                  onClick={() => setTaskState('confirmedCompletion', true)}
                  size='lg'
                  type='button'
                  variant='primary'
                >
                  Hide checklist
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='mb-3 font-semibold type-headline-3'>
              {isEmployerChecklist
                ? 'Run Your First Payroll'
                : 'Complete your profile'}
            </div>
            <div className='mb-5 text-secondary type-default'>
              {isEmployerChecklist
                ? "Let's get you ready to pay your team"
                : 'So that you can get paid'}
            </div>
          </>
        )}
      </div>
      <div>
        {getProgressBar(tasks)}
        {tasks.map((task) => (
          <div
            key={task.testId}
            className={`mb-4 ${
              task.state === TaskState.Pending ? 'cursor-pointer' : ''
            }`}
          >
            <Task {...task} />
          </div>
        ))}
      </div>
    </div>
  )
}

const getProgressBar = (tasks: TaskType[]) => {
  const completedCount = tasks.filter(
    (t) => t.state === TaskState.Completed
  ).length
  const percentComplete = (100.0 * completedCount) / tasks.length
  return (
    <div>
      <div className='flex mb-1 text-secondary type-caption'>
        <div className='flex-1'>PROGRESS</div>
        <div>
          {completedCount}/{tasks.length}
        </div>
      </div>
      <div className='w-full mb-3 rounded-md bg-gray-25'>
        <div
          className='h-2 rounded-md bg-primary-75'
          style={{ width: percentComplete + '%' }}
        />
      </div>
    </div>
  )
}

export default Checklist
