import * as React from 'react'
import { CookiesProvider } from 'react-cookie'
import { AppContainer } from './AppContainer'
import {
  FeaturesProvider,
  CurrentUserProvider,
  ConfigProvider,
  TrackingProvider,
  EnvironmentProvider,
  WindowProvider
} from './contexts'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

export type ApplicationProps = {
  name: string
}

export const withEcApplication =
  (
    Application: React.FC,
    testId: string,
    Fallback: NonNullable<React.ReactNode> = <div></div>,
    tailwindVersion: string = 'data-pui-2'
  ) =>
  (props: ApplicationProps) => {
    const { name } = props

    return (
      <React.Suspense fallback={Fallback}>
        <AppContainer
          testId={testId}
          application={name}
          tailwindVersion={tailwindVersion}
        >
          <WindowProvider>
            <EnvironmentProvider>
              <ConfigProvider>
                <CurrentUserProvider>
                  <TrackingProvider>
                    <FeaturesProvider>
                      <CookiesProvider>
                        <SnackBarProvider domRoot='ec-dashboard-spa'>
                          <Application />
                        </SnackBarProvider>
                      </CookiesProvider>
                    </FeaturesProvider>
                  </TrackingProvider>
                </CurrentUserProvider>
              </ConfigProvider>
            </EnvironmentProvider>
          </WindowProvider>
        </AppContainer>
      </React.Suspense>
    )
  }
