import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { withEcApplication } from '@local/ec-app'
import { App } from './App'

export const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: '*',
    element: null
  },
  {
    path: '/:client/dashboard',
    element: <App />
  }
])

const ApplicationRoot = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

export default withEcApplication(
  ApplicationRoot,
  'ec-dashboard-spa',
  <div className='pin-center'>
    <MerryGoRound />
  </div>
)
