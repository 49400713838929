import * as React from 'react'
import ReactTimeago from 'react-timeago'
import { isMobile } from 'react-device-detect'

import { ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons'

import { Notification } from '../constants'

type Props = {
  notifications: Array<Notification>
}

export const NotificationList = ({ notifications }: Props) => {
  const [pageIndex, setPageIndex] = React.useState(0)

  const PAGE_SIZE = React.useMemo(() => (isMobile ? 6 : 10), [])

  const startIndex = PAGE_SIZE * pageIndex
  const endIndex = Math.min(startIndex + PAGE_SIZE, notifications.length)
  const isFirstPage = pageIndex === 0
  const isLastPage = endIndex >= notifications.length

  const pagingMsg = `Showing ${startIndex + 1} - ${endIndex} of ${
    notifications.length
  } notifications`

  const notificationsToDisplay = React.useMemo(
    () => notifications.slice(startIndex, endIndex),
    [startIndex, endIndex, notifications]
  )

  return (
    <div className='-mx-6 md:mx-0'>
      {notificationsToDisplay.map((n) => getNotificationRow(n))}
      <div className='mt-6 text-center'>
        <div className='flex align-center'>
          <span
            className={`flex-1 text-right ${
              isFirstPage ? 'opacity-25' : 'cursor-pointer'
            }`}
            onClick={() => {
              !isFirstPage && setPageIndex(pageIndex - 1)
            }}
          >
            <ChevronLeftIcon />
          </span>
          <span className='self-center px-2 type-default'>{pagingMsg}</span>
          <span
            className={`flex-1 text-left ${
              isLastPage ? 'opacity-25' : 'cursor-pointer'
            }`}
            data-testid='next-page'
            onClick={() => {
              !isLastPage && setPageIndex(pageIndex + 1)
            }}
          >
            <ChevronRightIcon />
          </span>
        </div>
      </div>
    </div>
  )
}

const getNotificationRow = (notification: Notification) => {
  const { datePosted, isRead, id, messageTitle, url } = notification

  const readIcon = getReadIcon(isRead)
  return (
    <div
      className='flex px-5 py-3 cursor-pointer bg-gray-0 odd:bg-white'
      data-test-value='notification'
      key={id}
      onClick={() => {
        window.location.href = url
      }}
    >
      <div
        className='grid flex-1'
        style={{
          gridTemplateColumns: 'min-content auto',
          gridTemplateRows: 'auto auto'
        }}
      >
        <div></div> {/* Placeholder for alignment of grid */}
        <div className='pb-1 text-secondary type-default'>
          <ReactTimeago date={Date.parse(datePosted)} />
        </div>
        <div className='pr-6'>{readIcon}</div>
        <div className={`type-default ${isRead ? '' : 'font-semibold'}`}>
          {messageTitle}
        </div>
      </div>
      <div className='self-center'>
        <ChevronRightIcon />
      </div>
    </div>
  )
}

const getReadIcon = (read: boolean) => (
  <div
    className={`rounded-full inline-block ${
      read ? 'bg-gray-50' : 'bg-brand-50'
    }`}
    style={{ height: '10px', width: '10px' }}
  />
)
