export enum TaskState {
  Pending,
  Completed,
  Locked
}

export interface Task {
  label: string
  sublabel: string
  state: TaskState
  testId: string
  icon?: JSX.Element
  url?: string
  buttonTxt?: string
  tooltipText?: string
  onBtnClick?: () => void
}

export type Notification = {
  id: string
  datePosted: string
  messageTitle: string
  isRead: boolean
  url: string
}

export const PAYROLL_CARD_DESC =
  "When you're ready, prepare and process your upcoming payrolls"

export const TIMESHEETS_CARD_DESC =
  'Before you run payroll, be sure to review and approve open timesheets'

export const PAYSTUBS_CARD_DESC = 'Review your recent paystubs'

export type Permissions = {
  canViewOnboardingChecklist: boolean
  canViewEmployerChecklist: boolean
  canViewProcessPayroll: boolean
  canViewTimesheets: boolean
  canManageTodos: boolean
  canAddPayrollTodo: boolean
}

export type Links = {
  payrollDashboardUrl: string
  paystubsUrl: string
  personalProfileUrl: string
  i9Url: string
  w4Url: string
  docsAndFormsUrl: string
  directDepositUrl: string
  addEmployeesUrl: string
  timesheetsUrl: string
  uploadDocsUrl: string
  paymentMethodUrl: string
}

export type DataType = {
  preferredName: string
  links: Links
  permissions: Permissions
  isEmployeeZero: boolean
  employeeChecklist?: {
    finishedPersonalInfo?: boolean
    finishedI9?: boolean
    finishedW4?: boolean
    finishedSigningDocuments?: boolean
    finishedPaymentMethodSetup?: boolean
    skipChecklist?: boolean
    confirmedCompletion?: boolean
  }
  employerChecklist?: {
    finishedUploadingDocs?: boolean
    finishedAddingEmployees?: boolean
    finishedApprovingNewHires?: boolean
    finishedApprovingTimesheets?: boolean
    finishedFirstPayroll?: boolean
  }
  notifications: Array<Notification>
  isUnpaidDemoEmployee: boolean
  isI9ModuleEnabled: boolean
}
