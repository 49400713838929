import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card } from '@local/cards'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { ParagraphSkeleton } from '@local/skeletons'

type Props = {
  title: string
  icon: React.ReactNode
  description: string
  btnText: string
  url: string
  testId: string
}

// We either need all props to be defined or none (none is the loading state)
export const DashboardCard = (props: Props | {}) => {
  // @ts-ignore
  const { title, icon, description, btnText, url, testId } = props
  const isLoading = title === undefined
  return (
    <Card className='mb-6 type-default' testId={testId}>
      <div className='flex items-center'>
        {!isLoading ? (
          <>
            {icon}
            <div className='flex-1 ml-2 font-semibold type-large'>{title}</div>
          </>
        ) : (
          <Skeleton width={100} />
        )}
      </div>
      <div className='my-3'>
        {!isLoading ? (
          <span className='font-normal text-secondary'>{description}</span>
        ) : (
          <ParagraphSkeleton repeat={2} height={18} lastLineShorter />
        )}
      </div>
      <div className='text-right'>
        {!isLoading ? (
          <a
            href={url}
            className='inline-flex items-center font-semibold cursor-pointer text-primary-75'
          >
            <div className='pr-2'>{btnText}</div>
            <ArrowForwardIcon />
          </a>
        ) : (
          <Skeleton width={100} />
        )}
      </div>
    </Card>
  )
}

export const LOADING_DASHBOARD_CARD = <DashboardCard />
