import * as React from 'react'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Notification } from '../constants'
import { NotificationList } from './NotificationList'
import { getUnreadCount } from '../data_helpers'
import { NotificationIcon } from './NotificationIcon'
import { AddIcon, ChevronLeftIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { useParams } from 'react-router-dom'

type Props = {
  notifications?: Notification[]
  onboardingComplete: boolean
  isHrPlus?: boolean
  showAddTodoBtn?: boolean
  showAddPayrollTodoBtn?: boolean
  onClickBack: () => void
}

export const NotificationSection = ({
  notifications,
  onboardingComplete,
  isHrPlus,
  showAddTodoBtn,
  showAddPayrollTodoBtn,
  onClickBack
}: Props) => {
  const { client } = useParams<{ client: string }>()
  const unreadCount = useMemo(
    () => getUnreadCount(notifications),
    [notifications]
  )

  const getMainSection = () => {
    if (!notifications) {
      return (
        <div style={{ lineHeight: '150px' }}>
          <Skeleton count={3} height={75} />
        </div>
      )
    } else if (notifications.length > 0) {
      return <NotificationList notifications={notifications} />
    } else if (!onboardingComplete) {
      return (
        <div
          className='mt-1 text-secondary type-default md:ml-3'
          data-testid={`empty-notifications-onboarding${
            isHrPlus ? '-hr+' : ''
          }`}
        >
          {isHrPlus
            ? "No notifications yet. We'll notify you when your new employees have started filling out their profiles and documents."
            : "You have no notifications yet. We'll let you know when you get paid or have important tasks."}
        </div>
      )
    }
    return (
      <div
        className='mt-1 text-secondary type-default md:ml-3'
        data-testid='empty-notifications-msg'
      >
        You're free and clear for now
      </div>
    )
  }

  const addTodo = () => {
    window.location.href = `/mvc/${client}/Dashboard/Root/AddToDo`
  }

  const addPayrollTodo = () => {
    window.location.href = '/Dashboard/AddPayrollTodo.aspx'
  }

  return (
    <div className='mb-5'>
      <div className='flex items-center pb-3 -mx-3 md:mx-2'>
        <span
          className='mr-4 cursor-pointer md:hidden'
          data-testid='hide-notification-section-button'
          onClick={onClickBack}
        >
          <ChevronLeftIcon accessibility='decorative' />
        </span>
        <NotificationIcon alertCount={unreadCount} />
        <div className='flex-1 font-semibold type-headline-5'>
          <span className='pl-5'>Notifications</span>
        </div>
        {showAddTodoBtn && !showAddPayrollTodoBtn && (
          <IconButton
            icon={<AddIcon accessibility='decorative' size='md' />}
            onClick={addTodo}
          />
        )}
        {showAddTodoBtn && showAddPayrollTodoBtn && (
          <MenuDropdown
            renderToggle={(props: any) => (
              <IconButton
                {...props}
                icon={<AddIcon accessibility='decorative' size='md' />}
              />
            )}
            placement='bottom-end'
          >
            <ListItem
              onClick={addTodo}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              label='Add To Do'
            />
            <ListItem
              onClick={addPayrollTodo}
              label='Add Payroll To Do'
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            />
          </MenuDropdown>
        )}
      </div>
      <div>{getMainSection()}</div>
    </div>
  )
}
