import { useState } from 'react'
import { useTranslation } from '@local/translations'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Formik } from 'formik'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import * as yup from 'yup'
import { saveSsn } from '../resources/dashboardResource'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

type ErrorNames =
  | 'invalidSsn'
  | 'noItin'
  | 'ssnCaptureGenericError'
  | 'ssnExistsError'

interface SsnCaptureModalProps {
  isOpen: boolean
  onClose: Function
}

class SsnCapture {
  constructor(readonly ssn: string) {}

  // SSN format rules:
  // SSN must be numeric
  // SSN cannot be less than 9 digits
  // SSN cannot consist of 8 or 9 repeating digits (e.g., 111-11-1111, 111-11-1112)
  // SSN cannot begin with "9"
  // SSN cannot be "123-45-6789"
  // SSN cannot be "987-65-4321"
  // SSN cannot begin with "000"
  // SSN cannot begin with "666"
  // SSN cannot have "00" as the middle two digits
  // SSN cannot have "0000" as the last four digits
  // SSN must contain dash(es)
  private static SOCIAL_SECURITY_REGEX = new RegExp(
    '^(?!000|666|9|123-45-6789)(?!(\\d)\\1{2}-\\1{2}-\\1{3})(?!(\\d)(\\d)\\3{1}-\\3{2}-\\3{4})(\\d{3}-(?!00)\\d{2}-(?!0000)\\d{4})$'
  )

  static schema = yup
    .object({
      ssn: yup
        .string()
        .required('invalidSsn')
        .matches(SsnCapture.SOCIAL_SECURITY_REGEX, 'invalidSsn')
        .test(
          'no-leading-9',
          'noItin', // these 'error messages' correspond to I18N translation codes
          (value) => value != null && !value.startsWith('9')
        )
    })
    .defined()
}

export const SsnCaptureModal = ({
  isOpen,
  onClose,
  testId = 'ssn-capture-modal'
}: SsnCaptureModalProps & TestIdentifiable) => {
  const { t } = useTranslation()
  const { showSuccessSnackBar } = useSnackBar()
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const handleErrorMessage = (error: string) => {
    switch (error) {
      case 'invalidSsn':
        return t('invalidSsn')
      case 'noItin':
        return t('noItin')
      case 'ssnCaptureGenericError':
        return t('ssnCaptureGenericError')
      case 'ssnExistsError':
        return t('ssnExistsError')
      default:
        return ''
    }
  }
  return (
    <Formik<SsnCapture>
      initialValues={{
        ssn: ''
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SsnCapture.schema}
      onSubmit={(values, { setFieldError }) => {
        setIsSaving(true)
        saveSsn(values.ssn)
          .then(() => {
            showSuccessSnackBar(t('ssnCaptureSuccess'), { showIcon: true })
            onClose()
          })
          .catch((error: { message: ErrorNames }) => {
            setFieldError('ssn', t(error.message || ''))
          })
          .finally(() => {
            setIsSaving(false)
          })
      }}
    >
      {(props) => (
        <Modal
          isOpen={isOpen}
          overflowBehavior='modal'
          shouldCloseOnEsc={false}
          size='xxl'
          testId={`${testId}`}
        >
          <ModalHeader>
            <div className='font-bold mb-2'>{t('ssnCaptureModalHeader')}</div>
            <div className='font-normal text-gray-75 type-default mb-6'>
              {t('ssnCaptureModalSubHeader')}
            </div>
          </ModalHeader>

          <form onSubmit={props.handleSubmit}>
            <ModalBody>
              <NumberInput
                label={t('socialSecurityNumber')}
                format='###-##-####'
                allowEmptyFormatting
                mask={'_ '}
                inputMode='text'
                value={props.values.ssn ? props.values.ssn : ''}
                name='socialSecurityNumber'
                onChange={(event: any) => {
                  props.setFieldValue('ssn', event.formattedValue)
                }}
                invalid={props.errors.ssn != null}
                errorText={
                  props.errors.ssn ? handleErrorMessage(props.errors.ssn) : ''
                }
                testId='input-ssn'
                className='tabular-nums'
                containerClassName='mb-6 w-full'
              />
              <div className='text-gray-75 type-caption'>
                {t('ssnCaptureModalInputSubLabel')}
              </div>
            </ModalBody>
            <ModalFooter>
              {isSaving ? (
                <MerryGoRound size='xxs' />
              ) : (
                <Button type='submit' testId={`${testId}-save`}>
                  {t('save')}
                </Button>
              )}
            </ModalFooter>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
