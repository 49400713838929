import * as React from 'react'
import { AlertList } from './AlertList'
import { AlertContents } from './alerts'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'

type Props = {
  alerts: AlertContents[] | undefined
}

export const AlertSection = ({ alerts }: Props) => {
  const getAlerts = () => {
    return alerts?.length ? <AlertList alerts={alerts} /> : null
  }

  const alertsToShow = getAlerts()
  return (
    <div className='mb-5 w-full'>
      <div className='w-full'>{alertsToShow}</div>
      {alerts !== undefined && alerts.length !== 0 ? <DividingLine /> : null}
    </div>
  )
}
