import * as React from 'react'
import * as Sentry from '@sentry/browser'

type AppError = {
  hasError: boolean
  error?: Error
  info?: React.ErrorInfo
}

type AppErrorBoundaryProps = {
  children: React.ReactNode
  application: string
  testId?: string
  title?: string
  description?: string
}
/*
  last updated by Matthew Lee 7/30/20

  We are awaiting designs for exactly what the error state should be.
*/
export class AppErrorBoundary extends React.Component<
  AppErrorBoundaryProps,
  AppError
> {
  constructor(props: AppErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined,
      info: undefined
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true, error, info })

    // Send error to sentry with proper application tag
    Sentry.configureScope((scope) => {
      scope.setExtra('isEcSpaRenderingError', true)
      if (this.props.application) {
        scope.setExtra('application', this.props.application)
      }
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.info !== undefined) {
      const title = this.props.title || 'There was a problem with this page'
      const description =
        this.props.description ||
        `There was a technical issue that prevented this page from loading
      properly. Try reloading or loading another page in Toast Payroll.
      If that doesn't work please reach out to support.`

      if (this.state.error) {
        console.error(this.state.error)
      }

      return (
        <div className='max-w-screen-sm p-6 mx-auto' data-testid='error'>
          <div className=''>
            <div className='mb-3 type-headline-2' data-testid='title'>
              {title}
            </div>
            <div
              className='text-secondary type-large'
              data-testid='description'
            >
              {description}
            </div>
          </div>
        </div>
      )
    } else {
      return <>{this.props.children}</>
    }
  }
}
