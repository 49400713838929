import * as React from 'react'
import TimeAgo from 'react-timeago'

import { Button } from '@toasttab/buffet-pui-buttons'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'

import { DataType, Task, Notification, TaskState } from './constants'

export enum OnboardingStage {
  EMPLOYEE = 'employee',
  EMPLOYER = 'employer',
  DONE = 'done',
  HIDDEN = 'hidden'
}

export const getTaskList = (
  setTaskComplete: (taskId: string, complete: boolean) => void,
  data?: DataType,
  loadingTaskId?: string,
  isPaymentMethodActive?: boolean,
  echrSpfW4IsIn: boolean = false
): Task[] | undefined => {
  if (!data) {
    return // Task list is loading
  }
  const onboardingStage = getOnboardingStage(data)
  if (
    onboardingStage === OnboardingStage.DONE ||
    onboardingStage === OnboardingStage.HIDDEN
  ) {
    return [] // All relevant checklists completed
  }
  if (onboardingStage === OnboardingStage.EMPLOYEE) {
    return getEmployeeChecklist(
      data.employeeChecklist,
      data.links,
      data.isUnpaidDemoEmployee,
      data.isEmployeeZero,
      setTaskComplete,
      data.isI9ModuleEnabled,
      loadingTaskId,
      isPaymentMethodActive,
      echrSpfW4IsIn
    )
  }
  const {
    finishedUploadingDocs,
    finishedAddingEmployees,
    finishedFirstPayroll
  } = data.employerChecklist || {}
  const uploadDocumentsTask: Task = {
    label: 'Add company documents',
    sublabel: 'Required reading for new hires',
    state: finishedUploadingDocs ? TaskState.Completed : TaskState.Pending,
    buttonTxt: !finishedUploadingDocs
      ? loadingTaskId === 'finishedUploadingDocs'
        ? 'Marking complete...'
        : 'I’m done adding documents'
      : undefined,
    onBtnClick: () => setTaskComplete('finishedUploadingDocs', true),
    url: data.links.uploadDocsUrl,
    testId: 'upload-docs'
  }
  const addEmployeesTask: Task = {
    label: 'Add your employees',
    sublabel: 'Invite them to Toast Payroll',
    state: finishedAddingEmployees ? TaskState.Completed : TaskState.Pending,
    buttonTxt: !finishedAddingEmployees
      ? loadingTaskId === 'finishedAddingEmployees'
        ? 'Marking complete...'
        : 'I’m done adding employees'
      : undefined,
    onBtnClick: () => setTaskComplete('finishedAddingEmployees', true),
    url: data.links.addEmployeesUrl,
    testId: 'add-employees'
  }
  // ECHR-489: disabling this until we iron out paid/nonpaid E0
  // const approveTimeSheetsTask: Task = {
  //   label: 'Approve timesheets',
  //   sublabel: 'Make sure the hours are accurate',
  //   state: finishedApprovingTimesheets ? TaskState.Completed : TaskState.Pending,
  //   url: data.links.timesheetsUrl,
  //   testId: 'approve-timesheets'
  // }
  const completeFirstPayrollTask: Task = {
    label: 'Run your first payroll',
    sublabel: 'Everyone loves payday',
    state: finishedFirstPayroll
      ? TaskState.Completed
      : finishedAddingEmployees && finishedUploadingDocs // this also needs to include finishedApprovingTimesheets once the above comment is completed
      ? TaskState.Pending
      : TaskState.Locked,
    url: data.links.payrollDashboardUrl,
    testId: 'run-1st-payroll'
  }
  return [
    {
      label: 'Configure Toast Payroll',
      sublabel: 'Get the system ready to go',
      state: TaskState.Completed,
      testId: 'configure-payroll'
    },
    uploadDocumentsTask,
    addEmployeesTask,
    // ECHR-489: disabling this until we iron out paid/nonpaid E0
    // approveTimeSheetsTask,
    completeFirstPayrollTask
  ]
}

export const getCompletedTasksList = (tasks?: Task[]) => {
  return (tasks || [])
    .filter((task) => task.state === TaskState.Completed)
    .map((task) => task.testId)
}

// Marks a task in the proper checklist complete and updates
// the state by calling setData on the new data
export const markTaskComplete = (
  taskId: string,
  complete: boolean,
  data: DataType,
  setData: (data: DataType) => void
) => {
  // @ts-ignore
  const isEmployerChecklistTask = data.employerChecklist[taskId] !== undefined
  if (isEmployerChecklistTask) {
    const newChecklist = { ...data.employerChecklist }
    // @ts-ignore
    newChecklist[taskId] = complete
    setData({ ...data, employerChecklist: newChecklist })
  } else {
    const newChecklist = { ...data.employeeChecklist }
    // @ts-ignore
    newChecklist[taskId] = complete
    setData({ ...data, employeeChecklist: newChecklist })
  }
}

const getEmployeeChecklist = (
  employeeChecklist: DataType['employeeChecklist'],
  links: DataType['links'],
  isUnpaid: boolean,
  isEmployeeZero: boolean,
  setTaskComplete: (taskId: string, complete: boolean) => void,
  isI9ModuleEnabled: boolean,
  loadingTaskId?: string,
  isPaymentMethodActive?: boolean,
  echrSpfW4IsOn: boolean = false
) => {
  const {
    finishedPersonalInfo,
    finishedSigningDocuments,
    finishedI9,
    finishedW4,
    finishedPaymentMethodSetup
  } = employeeChecklist || {}

  let checklist: Task[] = [
    {
      label: 'Create your account',
      sublabel: 'Sign in anytime, anywhere',
      state: TaskState.Completed,
      testId: 'create-account'
    },
    {
      label: 'Add your personal info',
      sublabel: 'Necessary to get you paid',
      state: finishedPersonalInfo ? TaskState.Completed : TaskState.Pending,
      url: links.personalProfileUrl,
      testId: 'personal-info'
    }
  ]

  if (isI9ModuleEnabled) {
    checklist.push({
      label: 'Fill out your I-9',
      sublabel: 'Required to start working',
      state: finishedI9 ? TaskState.Completed : TaskState.Pending,
      url: links.i9Url,
      testId: 'i9'
    })
  }

  if (!isUnpaid) {
    const getW4State = (): TaskState => {
      const w4Unlocked = finishedI9 && finishedPersonalInfo
      if (w4Unlocked) {
        if (finishedW4) {
          return TaskState.Completed
        } else {
          return TaskState.Pending
        }
      } else if (echrSpfW4IsOn) {
        return TaskState.Locked
      } else {
        return TaskState.Pending
      }
    }

    const getW4LockedMessage = (
      finishedPersonalInfo: boolean | undefined,
      finishedI9: boolean | undefined
    ) => {
      if (!finishedPersonalInfo && !finishedI9) {
        return 'Please fill out your personal info and your I-9 before filling out your tax forms'
      } else if (finishedPersonalInfo && !finishedI9) {
        return 'Please fill out your I-9 before filling out your tax forms'
      } else {
        return 'Please fill out your personal info before filling out your tax forms'
      }
    }

    const w4LockedMessage = getW4LockedMessage(
      Boolean(finishedPersonalInfo),
      Boolean(finishedI9)
    )
    const showLockedMessage =
      echrSpfW4IsOn && (!finishedPersonalInfo || !finishedI9)

    checklist.push({
      label: 'Fill out your tax forms',
      sublabel: showLockedMessage
        ? w4LockedMessage
        : 'Make sure you’re paid correctly',
      state: getW4State(),
      tooltipText: showLockedMessage ? w4LockedMessage : undefined,
      url: links.w4Url,
      testId: 'w4'
    })
  }

  if (!isEmployeeZero) {
    checklist.push({
      label: 'Sign documents',
      sublabel: 'Learn the company policies',
      state: finishedSigningDocuments ? TaskState.Completed : TaskState.Pending,
      url: links.docsAndFormsUrl,
      testId: 'sign-docs'
    })
  }

  if (!isUnpaid) {
    if (isPaymentMethodActive) {
      checklist.push({
        label: 'Set your payment method',
        sublabel: 'Choose how you get paid',
        state: finishedPaymentMethodSetup
          ? TaskState.Completed
          : TaskState.Pending,
        url: `${links.paymentMethodUrl}?isFromOnboarding=true`,
        testId: 'payment-method'
      })
    } else {
      checklist.push({
        label: 'Set up direct deposit',
        sublabel: 'Get your paycheck more quickly',
        state: finishedPaymentMethodSetup
          ? TaskState.Completed
          : TaskState.Pending,
        buttonTxt: !finishedPaymentMethodSetup
          ? loadingTaskId === 'finishedPaymentMethodSetup'
            ? 'Marking complete...'
            : 'Skip this'
          : undefined,
        onBtnClick: () => setTaskComplete('finishedPaymentMethodSetup', true),
        url: links.directDepositUrl,
        testId: 'direct-deposit'
      })
    }
  }

  return checklist
}

export const isEmployeeChecklistComplete = (
  employeeChecklist: DataType['employeeChecklist'],
  isUnpaid: boolean,
  isEmployeeZero: boolean
) => {
  if (!isEmployeeZero) {
    return (
      employeeChecklist?.finishedPersonalInfo &&
      employeeChecklist?.finishedI9 &&
      employeeChecklist?.finishedW4 &&
      employeeChecklist?.finishedSigningDocuments &&
      employeeChecklist?.finishedPaymentMethodSetup
    )
  } else if (!isUnpaid) {
    return (
      employeeChecklist?.finishedPersonalInfo &&
      employeeChecklist?.finishedI9 &&
      employeeChecklist?.finishedW4 &&
      employeeChecklist?.finishedPaymentMethodSetup
    )
  } else {
    return (
      employeeChecklist?.finishedPersonalInfo && employeeChecklist?.finishedI9
    )
  }
}

export const getOnboardingStage = (data: DataType) => {
  const hasRunFirstPayroll = data.employerChecklist?.finishedFirstPayroll
  if (!data.employeeChecklist || !data.permissions.canViewOnboardingChecklist) {
    return OnboardingStage.HIDDEN
  }
  const isChecklistComplete = isEmployeeChecklistComplete(
    data?.employeeChecklist,
    data?.isUnpaidDemoEmployee,
    data?.isEmployeeZero
  )
  if (
    !data.employeeChecklist.skipChecklist &&
    ((!hasRunFirstPayroll && !isChecklistComplete) ||
      (isChecklistComplete && !data.employeeChecklist.confirmedCompletion))
  ) {
    return OnboardingStage.EMPLOYEE
  }
  if (
    data.permissions &&
    data.permissions.canViewEmployerChecklist &&
    !hasRunFirstPayroll
  ) {
    return OnboardingStage.EMPLOYER
  }
  return OnboardingStage.DONE
}

export const hasSkippedEmployeeChecklist = (data: DataType) => {
  const isEEComplete = isEmployeeChecklistComplete(
    data?.employeeChecklist,
    data?.isUnpaidDemoEmployee,
    data?.isEmployeeZero
  )
  return !isEEComplete && data.employeeChecklist?.skipChecklist
}

export const getUnreadCount = (notifications?: Notification[]) =>
  (notifications || []).filter((n) => !n.isRead).length

export const getChecklistNavButton = (
  data: DataType | undefined,
  setTaskComplete: (taskId: string, complete: boolean) => void
) => {
  if (!data) {
    return
  }
  const onboardingStage = getOnboardingStage(data)
  const isEmployeeChecklist = onboardingStage === OnboardingStage.EMPLOYEE
  const showChecklistNavButton =
    data?.isEmployeeZero &&
    (isEmployeeChecklist
      ? !data.employerChecklist?.finishedFirstPayroll
      : hasSkippedEmployeeChecklist(data))
  if (!showChecklistNavButton) {
    return null
  }

  return (
    <div className='flex justify-center'>
      <Button
        variant='link'
        onClick={() => setTaskComplete('skipChecklist', isEmployeeChecklist)}
        iconLeft={isEmployeeChecklist ? null : <ChevronLeftIcon />}
        data-testid={isEmployeeChecklist ? 'skip-checklist' : 'prev-checklist'}
      >
        {isEmployeeChecklist
          ? 'Skip to payroll setup'
          : 'Back to account setup'}
      </Button>
    </div>
  )
}
