import * as React from 'react'
import { BaseCard } from '@toasttab/buffet-pui-card'

type CardProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  testId?: string
}

export const Card = ({
  children,
  className,
  style,
  testId = ''
}: CardProps) => {
  return (
    <div className={className} data-testid={testId} style={style}>
      <BaseCard className='h-full'>{children}</BaseCard>
    </div>
  )
}
